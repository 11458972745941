<template>
    <v-layout column="" row wrap pr-3 mr-2 mt-1>

        <v-row>

            <v-data-table :headers="headers" :items="ownres" :page.sync="page" @page-count="pageCount = $event"
                sort-by="calories" class="elevation-1 request_table" style="width:96%" items-per-page="15"
                :search="search" hide-default-footer="" :loading="loading" loading-text="جاري تحميل البيانات">

                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title>فواتير التجار</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>


                    </v-toolbar>
                </template>


                <template v-slot:bottom>
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </template>





                <template v-slot:item.edit="{ item }">

                    <v-btn color="success" :to="'/BillsByOwnerId/'+item.id">
                   عرض الفواتير
                        </v-btn>

                  
                </template>



            </v-data-table>

        </v-row>
        <v-row>
            <!-- <v-pagination v-model="page"  prev-icon="mdi-menu-right" next-icon="mdi-menu-left"
                circle="" :length="pageCount"></v-pagination> -->
        </v-row>
    </v-layout>

</template>


<script>
    export default {
        data: () => ({
            ownres: [],

            editedItem: {



                user: {
                    created_at: "",
                    full_name: "",
                    id: "",
                    mobile_token: "",
                    remember_token: "",
                    role_id: "",
                    password_confirmation: "",
                    status_id: "",
                    updated_at: "",
                },

                owner_category: [{
                    id: "",
                    category_id: "",
                    owner_id: "",
                    status_id: "",
                    created_at: "",

                }],

                owner_description: "",
                owner_email: "",
                password: "",
                user_phone: "",
                owner_barnd_name: "",
                owner_phone: "",
                status_id: "",
                owner_type_id: "",
                province_id: "",
                category_id: "",
                long: "66",
                lat: "32",

            },

            calander_dialog: false,
            err_show: false,
            error: '',
            search: '',
            item_selected: {},
            loding_accept: false,
            loding_cancel: false,
            headers: [{
                    text: '#',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'اسم التاجر',
                    align: 'center',
                    sortable: false,
                    value: 'user.full_name',
                },
                {
                    text: 'اسم الشركة',
                    align: 'center',
                    sortable: false,
                    value: 'owner_barnd_name',
                },

                 {
                    text: 'رقم الهاتف',
                    align: 'center',
                    sortable: false,
                    value: 'owner_phone',
                },
            

                {
                    text: '',
                    value: 'edit',
                    sortable: false
                },


            ],
            editedIndex: -1,


            valid: false,
            page: 1,
            pageCount: 0,
            current_page: 0,
            last_page: 0,
            loading: false


        }),

        updated() {},
        methods: {

            editItem(item) {
                this.editedIndex = 1;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            getOWner() {

                var url = "/operation/getOwnersByBillsStatusId/32";
                this.loading = true;
                this.$http({
                    method: 'get',
                    url: url,
                    headers: {

                    }

                }).then(response => {
                    this.ownres = response.data.data.data;
                }).catch(error => {
                    error
                    //this.$swal('خطاء', "خطاء بالاتصال", 'error')
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },

        },

        mounted() {

            this.getOWner();


        },
        computed: {



            // selected:function() {

            // },
        },


        watch: {
            selected: 'search by sub_cat_id',
        },
    }
</script>